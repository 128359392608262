<template>
  <div style="margin-top: 140px">
    <v-container>
      <div style="max-width: 70px">
        <v-divider
          class="primary"
          style="padding: 7px 0; opacity: 1 !important"
        ></v-divider>
      </div>
      <h2
        class="font-weight-bold mb-5"
        :class="[$vuetify.breakpoint.smAndDown ? 'texth4' : 'texth3']"
      >
        Mensaje del Equipo <br />
        de Escritores
      </h2>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12" md="8">
          <p v-for="m in message" :key="m.key">
            {{ m }}
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <v-img class="mx-auto" max-width="400" src="@/assets/logo_blue.png"></v-img>
        </v-col>
      </v-row>
      <div class="py-6"></div>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: [
        "En Discursa creemos en el poder de la palabra. Sabemos que para convencer y persuadir, es necesario conectar emocionalmente con las diferentes audiencias. ",
        "Confeccionar una pieza discursiva memorable es un proceso que exige talento y profesionalismo, pero sobre todo, amplia experiencia. Por eso, en muchos sentidos, el discurso es todo un arte.",
        "La clave está en seleccionar las palabras adecuadas, el orden preciso, el énfasis y tono exactos, para luego valorar si corresponden a lo que se quiere transmitir y a lo que la audiencia necesita escuchar. Y sólo entonces, nuestro discurso puede convertirse en un poderoso instrumento de persuasión.",
        "Discursa es el primer proyecto de consultoría en México especializado en la elaboración de discursos. Somos un grupo de profesionales que, desde hace más de 15 años, se ha dedicado exclusivamente a escribir discursos para presidentes de la República, secretarios de estado, gobernadores, legisladores, candidatos y líderes empresariales.",
        "Cada uno de nosotros cuenta con amplia experiencia en comunicación, políticas públicas y mensaje gubernamental producto de años dedicados al diseño de contenidos y narrativas innovadoras a partir de los más altos estándares de calidad y excelencia.",
        "En Discursa no nos conformamos solo con darle a nuestros clientes lo que piden, sino que les proporcionamos lo que creemos que realmente necesitan.",
        "Nuestra misión es potenciar sus ideas y convertirlas en piezas discursivas que trasciendan.",
      ],
      dialog: false,
      color: "#f9f9f9",
    };
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
